import isNullOrUndefined from "@kwaleeltd/common/dist/utils/isNullOrUndefined";
import {
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ValueType } from "../../model/versioned-settings.model";
import matches from "../../utils/matches";
import { generateNewId } from "./utils/generateNewId";
import { getAllChildren } from "./utils/getAllChildren";
import { SettingTreeItem } from "./SettingTree";
import { getNewModified } from "./SettingTreeRow";
import { getSiblings } from "./utils/getSiblings";

const appSettingsAdapter = createEntityAdapter<SettingTreeItem>({
  selectId: ({ id }) => id
});

type AppSettingsState = {
  editMode?: boolean;
  game_edition_id?: string;
  max_version?: string;
  country_overrides?: string[];
  entities: Dictionary<SettingTreeItem>;
  uneditedSettings: SettingTreeItem[];
  showUnsavedDialog?: boolean;
  ids: EntityId[];
  expandedSettingIds: EntityId[];
};

const initialState: AppSettingsState = {
  ...appSettingsAdapter.getInitialState(),
  uneditedSettings: [],
  expandedSettingIds: [],
  editMode: true
};

const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    addSetting: (state, action) => {
      const maxId = action.payload.treeData.length
        ? Math.max(
            ...action.payload.treeData.map((item: SettingTreeItem) => item.id)
          )
        : 0;

      const newId = maxId + 1;

      const parentSetting = state.entities[action.payload.parentId];
      const settingName = action.payload.name ?? "newSetting";

      const newSetting: SettingTreeItem = {
        id: newId,
        name: settingName,
        parentId: action.payload.parentId ?? 0,
        hasChildren: false,
        type: action.payload.type ? action.payload.type : ValueType.STRING,
        tag: "",
        description: "",
        value: "",
        path: [...(parentSetting?.path ?? []), settingName],
        ...getNewModified()
      };

      appSettingsAdapter.addOne(state, newSetting);
    },
    duplicateSetting: (state, action) => {
      let dupedSettings: SettingTreeItem[] = [];
      const settingToDupe = action.payload.setting;
      const allSettings = action.payload.treeData;
      const settingIds = state.ids as number[];

      // Create an ID map for updating the parentIds of settings
      const idMap: Record<number, number> = {};

      const newParentId = generateNewId(settingIds);

      idMap[settingToDupe.id] = newParentId;

      const newSettingIds = [...settingIds, newParentId];

      // All children of setting (as every child needs duplicating in normalized array)
      const children = getAllChildren(
        settingToDupe.id,
        action.payload.treeData
      ).sort((childA, childB) => childA.id - childB.id);

      // Create a map of old ids to new ids to keep parent/child relationships
      for (const [index, child] of Object.entries(children)) {
        // Generate new id
        idMap[child.id] = generateNewId(newSettingIds, parseInt(index) + 1);
      }

      // If parent is array - setting name must be next element in array
      const parentSetting = action.payload.treeData.find(
        (setting: SettingTreeItem) => setting.id === settingToDupe.parentId
      );
      let newSettingName = `${settingToDupe.name} Duplicated`;

      if (parentSetting && parentSetting.type === ValueType.ARRAY) {
        const siblings = getSiblings(allSettings, parentSetting.id);
        newSettingName = siblings.length.toString();
      }

      const dupedParentSetting = {
        ...settingToDupe,
        ...getNewModified(),
        name: newSettingName,
        parentId: idMap[settingToDupe.parentId] ?? settingToDupe.parentId ?? 0,
        id: idMap[settingToDupe.id]
      };

      dupedSettings.push(dupedParentSetting);

      // If parentId appears in this new id map, update to new id
      for (const child of children) {
        const dupedSetting = {
          ...child,
          ...getNewModified(),
          name: child.name,
          parentId: idMap[child.parentId] ?? 0,
          id: idMap[child.id]
        };

        dupedSettings.push(dupedSetting);
      }

      appSettingsAdapter.addMany(state, dupedSettings);
    },
    updateSetting: (state, action) => {
      appSettingsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          ...action.payload.changes,
          ...getNewModified()
        }
      });
    },
    updateSettings: (state, action) => {
      const updatedSettings = action.payload.updatedSettings.map(
        (setting: SettingTreeItem) => ({
          ...setting,
          ...getNewModified()
        })
      );
      appSettingsAdapter.updateMany(state, updatedSettings);
    },
    removeSetting: appSettingsAdapter.removeOne,
    setSettings: appSettingsAdapter.setAll,
    addSettings: appSettingsAdapter.addMany,
    clearUneditedSettings: (state) => {
      state.uneditedSettings = [];
    },
    setUnsavedDialog: (state, action) => {
      if (action.payload.revertSettings) {
        appSettingsAdapter.setAll(state, state.uneditedSettings);
        state.editMode = false;
      }

      state.showUnsavedDialog = action.payload.showUnsavedDialog;
    },
    setEditMode: (state, action) => {
      // If going into edit mode - push current settings to state
      if (action.payload.editMode) {
        state.uneditedSettings = action.payload.settings;
        state.editMode = true;
      } else {
        if (
          !matches(action.payload.settings, state.uneditedSettings, {
            fuzzyComparison: true
          })
        ) {
          state.showUnsavedDialog = true;
        } else {
          // Otherwise load previous unedited settings back
          state.editMode = false;
        }
      }
    },
    expandSetting: (state, action) => {
      state.expandedSettingIds.push(action.payload);
    },
    collapseSetting: (state, action) => {
      const index = state.expandedSettingIds.indexOf(action.payload);

      if (!isNullOrUndefined(index)) {
        state.expandedSettingIds.splice(index, 1);
      }
    }
  }
});

export const selectEditMode = (state: RootState) =>
  state.settings.present.editMode;

export const selectShowUnsavedDialog = (state: RootState) =>
  state.settings.present.showUnsavedDialog;

export const selectExpandedSettingIds = (state: RootState) =>
  state.settings.present.expandedSettingIds;

export const selectPastLength = (state: RootState) =>
  state.settings.past.length;

export const selectFutureLength = (state: RootState) =>
  state.settings.future.length;

export const selectAllSettings = appSettingsAdapter.getSelectors<RootState>(
  (state) => state.settings.present
).selectAll;

export const {
  addSetting,
  duplicateSetting,
  setSettings,
  addSettings,
  updateSetting,
  removeSetting,
  expandSetting,
  collapseSetting,
  updateSettings,
  setEditMode,
  setUnsavedDialog,
  clearUneditedSettings
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
