export interface Endpoint {
  url: string;
  object_path: string;
  single_object_path?: string;
}

export default class Endpoints {
  static readonly VERSIONED_APP_SETTINGS: Endpoint = {
    url: "/admin/versioned-app-settings",
    object_path: "versioned_app_settingss",
    single_object_path: "versioned_app_setting",
  };

  static readonly VERSIONED_APP_SETTINGS_OVERRIDES: Endpoint = {
    url: "/admin/versioned-app-settings-overrides",
    object_path: "versioned_app_settings_overrides",
    single_object_path: "versioned_app_setting_override",
  };
}
