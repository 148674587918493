import TabContext from "@mui/lab/TabContext";

import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  darken,
  Divider,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect } from "react";
//@ts-ignore
import JSONInput from "react-json-editor-ajrm";
//@ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import { useDispatch, useSelector } from "react-redux";
import { selectAllSettings, setSettings } from "./appSettingsSlice";
import Filter from "./Filter";
import { selectAllOverrides } from "./settingsOverridesSlice";
import SettingTree from "./SettingTree";
import { createNestedSettings } from "./utils/createNestedSettings";
import { flattenSettings } from "./utils/flattenSettings";
import { JsonEditor as Editor } from "jsoneditor-react";
import ace from "brace";

import "jsoneditor-react/es/editor.min.css";

import "brace/mode/json";
import "brace/theme/monokai";

const SettingWindow = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? "#fff"
      : darken(theme.palette.background.paper, 0.3),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "4px"
}));

const StyledPanel = styled(TabPanel)(() => ({
  padding: 0
}));

export default function VersionedAppSettings() {
  const dispatch = useDispatch();

  const currentSettings = useSelector(selectAllSettings);
  const currentOverrides = useSelector(selectAllOverrides);

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const theme = useTheme();

  useEffect(() => {
    localStorage.setItem("user_id", "external_user");
  }, [dispatch]);

  const jsonSettings = createNestedSettings(currentSettings);

  return (
    <Box sx={{ maxWidth: "1100px", margin: "auto" }}>
      <Typography variant="h1">Game Settings Editor</Typography>
      <Divider sx={{ margin: `${theme.spacing(1)} ${theme.spacing(2)}` }} />
      <Box display="flex" width="100%">
        <SettingWindow>
          <TabContext value={value}>
            <Filter tabValue={value} handleTabChange={handleChange} />

            <StyledPanel value="1">
              <SettingTree
                treeData={currentSettings}
                overrideTreeData={currentOverrides}
              />
            </StyledPanel>
            <StyledPanel value="2">
              <Editor
                ace={ace}
                mode="code"
                htmlElementProps={{
                  style: {
                    height: "75vh"
                  }
                }}
                value={jsonSettings}
                theme="ace/theme/monokai"
                onChange={(e: any) => {
                  dispatch(setSettings(flattenSettings(e ?? {})));
                }}
              />
              <div style={{ position: "absolute", right: 8, top: 8 }}>
                Hello
              </div>
            </StyledPanel>
          </TabContext>
        </SettingWindow>
      </Box>
    </Box>
  );
}
