// Endpoints dependant on URL
import axios, { CancelToken } from "axios";

enum Environments {
  staging,
  live,
}

const serverURLs: Record<keyof typeof Environments, string> = {
  staging: "https://uat-playphoto-admin.servers.kwalee.com:947/",
  live: "https://admin.servers.kwalee.com:948/",
};

const environmentNames: Record<keyof typeof Environments, string> = {
  staging: "Staging",
  live: "Live",
};

export function getEnvironment() {
  const envKey = (process.env.REACT_APP_ENV ??
    "staging") as keyof typeof Environments;

  return environmentNames[envKey];
}

export function getServerURL() {
  const envKey = (process.env.REACT_APP_ENV ??
    "staging") as keyof typeof Environments;

  return serverURLs[envKey];
}

export const getContextUrl = () => {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port
  );
};

const API_URL = getServerURL();

let sessionId: string;
let email: string;
let gameId: string;

export const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const MAX_REQUESTS_COUNT = 50;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

/**
 * Axios Request Interceptor
 */
axiosConfig.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
axiosConfig.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

export interface RequestAdditions {
  interface_id: string;
  game_id: string;
  session_id: string;
  user_email: string;
}

export function getRequestAdditions(): RequestAdditions {
  gameId = localStorage.getItem(`game_id_${getContextUrl()}`)!;
  sessionId =
    localStorage.getItem("session_id") ??
    localStorage.getItem(`session_id_${API_URL}${gameId}`)!;
  email = localStorage.getItem("user_id")!;

  return {
    interface_id: `game_admin_${gameId}`,
    game_id: gameId,
    session_id: sessionId,
    user_email: email,
  };
}

export function getData<T = any>(
  uri: string,
  data?: {},
  cancelToken?: CancelToken,
  onDownloadProgress?: any,
  objectPath?: string
) {
  gameId = localStorage.getItem(`game_id_${getContextUrl()}`)!;
  sessionId =
    localStorage.getItem("session_id") ??
    localStorage.getItem(`session_id_${API_URL}${gameId}`)!;
  email = localStorage.getItem("user_id")!;

  return axiosConfig
    .get<T>(uri, {
      params: {
        ...getRequestAdditions(),
        ...data,
      },
      cancelToken,
      onDownloadProgress,
    })
    .then((res: { data: { [id: string]: any } }) => {
      let data;
      if (objectPath) {
        data = res.data[objectPath];
      } else {
        data = res.data;
      }
      return data;
    });
}

export function postData<T = any>(
  uri: string,
  data: {} = {},
  objectPath?: string,
  config?: { [id: string]: any }
) {
  const params = generateParams(data);

  return axiosConfig
    .post<T>(uri, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      ...config,
    })
    .then((res: { data: { [id: string]: any } }) => {
      let data;

      if (objectPath) {
        data = res.data[objectPath];
      } else {
        data = res.data;
      }
      return data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteData<T = any>(
  uri: string,
  data?: {},
  cancelToken?: CancelToken
) {
  gameId = localStorage.getItem(`game_id_${getContextUrl()}`)!;
  sessionId =
    localStorage.getItem("session_id") ??
    localStorage.getItem(`session_id_${API_URL}${gameId}`)!;
  email = localStorage.getItem("user_id")!;

  return axiosConfig
    .post<T>(uri, {
      params: {
        ...getRequestAdditions(),
        ...data,
      },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    });
}

export const generateParams = (data: Record<string, any>) => {
  const params = new URLSearchParams();

  for (const key in data) {
    let val = (data as any)[key];
    if (typeof val === "object") {
      val = JSON.stringify(val);
    }

    params.append(key, val);
  }

  const additions = getRequestAdditions();
  for (const key in additions) {
    let val = (additions as any)[key];
    if (typeof val === "object") {
      val = JSON.stringify(val);
    }

    params.append(key, val);
  }

  return params;
};
