import React from "react";
import VersionedAppSettings from "./features/VersionedAppSettings/VersionedAppSettings";

function App() {
  return (
    <div className="App">
      <VersionedAppSettings />
    </div>
  );
}

export default App;
