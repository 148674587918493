import { ValueType } from "../../../model/versioned-settings.model";
import { SettingTreeItem } from "../SettingTree";

export function getAllChildren(
  settingId: number,
  settings: SettingTreeItem[]
): SettingTreeItem[] {
  const directChildren = settings.filter(
    (setting) => setting.parentId === settingId
  );

  let allChildren: SettingTreeItem[] = [...directChildren];

  // Loop through all direct children
  for (const child of directChildren) {
    if (child.type === ValueType.ARRAY || child.type === ValueType.OBJECT) {
      allChildren = allChildren.concat(getAllChildren(child.id, settings));
    }
  }

  return allChildren;
}
