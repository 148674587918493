import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { SettingTreeOverride } from "../../model/versioned-settings.model";

const settingsOverridesAdapter = createEntityAdapter<SettingTreeOverride>({
  selectId: ({ id }) => id,
});

const settingsOverridesSlice = createSlice({
  name: "settingsOverrides",
  initialState: settingsOverridesAdapter.getInitialState(),
  reducers: {
    setOverrides: settingsOverridesAdapter.setAll,
  },
});

export const selectAllOverrides =
  settingsOverridesAdapter.getSelectors<RootState>(
    (state) => state.overrides
  ).selectAll;

export const { setOverrides } = settingsOverridesSlice.actions;

export default settingsOverridesSlice.reducer;
