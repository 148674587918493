import {
  NestedSetting,
  NestedSettings,
  SettingValue,
  ValueType,
} from "../../../model/versioned-settings.model";
import { SettingTreeItem } from "../SettingTree";

export const createNestedSettings = (flattenedSettings: SettingTreeItem[]) => {
  const nestedSettings: NestedSettings = {};
  const topLevelSettings = flattenedSettings.filter(
    (setting) => setting.parentId === 0
  );

  for (const setting of topLevelSettings) {
    nestedSettings[setting.name] = createSettingLevel(
      setting,
      flattenedSettings
    );
  }

  return nestedSettings;
};

const createSettingLevel = (
  flattenedSetting: SettingTreeItem,
  flattenedSettings: SettingTreeItem[]
): NestedSetting => {
  let settingValue: SettingValue<any>;

  const children = flattenedSettings
    .filter((setting) => setting.parentId === flattenedSetting.id)
    .sort((a, b) =>
      flattenedSetting?.type === ValueType.ARRAY
        ? parseInt(a.name) - parseInt(b.name)
        : a.name.localeCompare(b.name)
    );

  switch (flattenedSetting.type) {
    case ValueType.OBJECT:
      // Loop and add to setting
      for (const childSetting of children) {
        if (typeof settingValue !== "object") {
          settingValue = {};
        }

        settingValue[childSetting.name] = createSettingLevel(
          childSetting,
          flattenedSettings
        );
      }

      break;
    case ValueType.ARRAY:
      // Loop and add to setting
      for (const childSetting of children) {
        if (!Array.isArray(settingValue)) {
          settingValue = [];
        }
        settingValue.push(createSettingLevel(childSetting, flattenedSettings));
      }

      break;
    default:
      settingValue = flattenedSetting.value;
      break;
  }

  return {
    __value__: settingValue,
    __type__: flattenedSetting.type,
    __user__: flattenedSetting.user,
    __modified__: flattenedSetting.modified,
    __description__: flattenedSetting.description,
    __tag__: flattenedSetting.tag,
  };
};
