import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";
import { appSettingsApi } from "../features/VersionedAppSettings/network/appSettingsApi";
import appSettingsReducer, {
  collapseSetting,
  expandSetting,
} from "../features/VersionedAppSettings/appSettingsSlice";
import settingsOverridesReducer from "../features/VersionedAppSettings/settingsOverridesSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import undoable, { excludeAction } from "redux-undo";

const persistConfig = {
  key: "settings",
  storage,
};

const persistedSettingsReducer = persistReducer(
  persistConfig,
  appSettingsReducer
);

const rootReducer = combineReducers({
  [appSettingsApi.reducerPath]: appSettingsApi.reducer,
  settings: undoable(persistedSettingsReducer, {
    filter: excludeAction([collapseSetting, expandSetting]),
  }),
  overrides: settingsOverridesReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
