import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Endpoints from "../../../constants/endpoints";
import {
  INormVersionedAppSettings,
  INormVersionedAppSettingsOverrides,
  IVersionedAppSettings,
  IVersionedAppSettingsOverrides
} from "../../../model/versioned-settings.model";
import { generateParams, getRequestAdditions, getServerURL } from "./request";
import { flattenSettings } from "../utils/flattenSettings";

const API_URL = getServerURL();

type AppSettingsResponse = {
  session_id: string;
  versioned_app_settingss: IVersionedAppSettings[];
};

type NormAppSettingsResponse = {
  session_id: string;
  versioned_app_settingss: INormVersionedAppSettings[];
};

type AppSettingsOverridesResponse = {
  session_id: string;
  versioned_app_settings_overrides: IVersionedAppSettingsOverrides[];
};

type NormAppSettingsOverridesResponse = {
  session_id: string;
  versioned_app_settings_overrides: INormVersionedAppSettingsOverrides[];
};

export const appSettingsApi = createApi({
  reducerPath: "appSettingsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getVersionedAppSettings: builder.query<NormAppSettingsResponse, unknown>({
      query: () => {
        const defaultParams = getRequestAdditions();
        return {
          url: Endpoints.VERSIONED_APP_SETTINGS.url,
          params: defaultParams
        };
      },
      //@ts-ignore
      transformResponse: (
        response: AppSettingsResponse
      ): NormAppSettingsResponse => {
        // Normalize all settings objects and set settings in state
        const normalizedObjects = response.versioned_app_settingss.map(
          (setting) => ({
            ...setting,
            nested_settings: flattenSettings(setting.nested_settings)
          })
        );

        return { ...response, versioned_app_settingss: normalizedObjects };
      }
    }),

    updateSettings: builder.mutation<any, IVersionedAppSettings>({
      query(settings) {
        const params = generateParams(settings);

        return {
          url: `${Endpoints.VERSIONED_APP_SETTINGS.url}/${settings.object_id}/update`,
          method: "POST",
          body: params
        };
      }
    }),
    createSettings: builder.mutation<any, Partial<IVersionedAppSettings>>({
      query(settings) {
        const params = generateParams(settings);

        return {
          url: Endpoints.VERSIONED_APP_SETTINGS.url,
          method: "POST",
          body: params
        };
      }
    }),
    getVersionedAppSettingsOverrides: builder.query<
      NormAppSettingsOverridesResponse,
      unknown
    >({
      query: () => {
        const defaultParams = getRequestAdditions();
        return {
          url: Endpoints.VERSIONED_APP_SETTINGS_OVERRIDES.url,
          params: defaultParams
        };
      },
      //@ts-ignore
      transformResponse: (
        response: AppSettingsOverridesResponse
      ): NormAppSettingsOverridesResponse => {
        // Normalize all settings objects and set settings in state
        const normalizedObjects = response.versioned_app_settings_overrides.map(
          (override) => ({
            ...override,
            nested_overrides: override.nested_overrides.map(
              (nestedOverride) => ({
                ...nestedOverride,
                nested_settings: flattenSettings({
                  "1": nestedOverride.nested_settings
                }).map((setting) => ({
                  ...setting,
                  override_key: override.override_key,
                  override_type: override.override_type,
                  delete: false
                }))
              })
            )
          })
        );

        return {
          ...response,
          versioned_app_settings_overrides: normalizedObjects
        };
      }
    }),
    updateOverrides: builder.mutation<any, IVersionedAppSettingsOverrides>({
      query(override) {
        const params = generateParams(override);

        return {
          url: `${Endpoints.VERSIONED_APP_SETTINGS_OVERRIDES.url}/${override.object_id}/update`,
          method: "POST",
          body: params
        };
      }
    }),
    createOverrides: builder.mutation<
      any,
      Partial<IVersionedAppSettingsOverrides>
    >({
      query(override) {
        const params = generateParams(override);

        return {
          url: Endpoints.VERSIONED_APP_SETTINGS_OVERRIDES.url,
          method: "POST",
          body: params
        };
      }
    })
  })
});

export const {
  useGetVersionedAppSettingsQuery,
  useGetVersionedAppSettingsOverridesQuery,
  useUpdateSettingsMutation,
  useCreateSettingsMutation,
  useCreateOverridesMutation,
  useUpdateOverridesMutation
} = appSettingsApi;
