import { TextField } from "@mui/material";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NestedSetting } from "../../model/versioned-settings.model";

const StyledTextField = styled(TextField)`
  color: white !important;
`;

export const DescriptionField = ({
  initialValue,
  updateSetting,
}: {
  initialValue: string;
  updateSetting: (setting: Partial<NestedSetting>) => void;
}) => {
  const [value, setValue] = useState(initialValue);
  const inputEl = useRef<HTMLInputElement>(null);

  const updateValue = (value: string) => {
    if (value !== initialValue) {
      updateSetting({ __description__: value });
    }
  };

  const debouncedSetValue = AwesomeDebouncePromise(updateValue, 500);

  useEffect(() => {
    const inputRef = inputEl.current;

    inputRef?.blur();
  }, [value]);

  return (
    <StyledTextField
      ref={inputEl}
      multiline
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        debouncedSetValue(event.target.value);
      }}
    />
  );
};
