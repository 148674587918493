import { SettingTreeItem } from "../SettingTree";

export function flattenSettings(
  map: { [id: string]: any },
  flattenedMap?: SettingTreeItem[],
  parentIndex?: number,
  path?: string[]
): SettingTreeItem[] {
  let newFlattenedMap = [...(flattenedMap ?? [])];
  const keys = Object.keys(map);

  // If map contains folders or files
  keys.forEach((settingName) => {
    let valueWithMetaData = map[settingName];
    let settingValue = valueWithMetaData.__value__;

    const newPath = [...(path ?? []), settingName];

    if (settingValue === null) {
      return;
    }

    const isCollection =
      Array.isArray(settingValue) || typeof settingValue === "object";
    newFlattenedMap.push({
      id: newFlattenedMap.length + 1,
      name: settingName,
      parentId: parentIndex ?? 0,
      hasChildren:
        typeof settingValue === "object" &&
        Object.keys(settingValue).length !== 0,
      type: valueWithMetaData.__type__,
      tag: valueWithMetaData.__tag__ ?? "",
      description: valueWithMetaData.__description__ ?? "",
      value: (!isCollection && settingValue) || null,
      user: valueWithMetaData.__user__,
      modified: valueWithMetaData.__modified__,
      path: newPath,
    });

    if (isCollection && Object.keys(settingValue).length !== 0) {
      newFlattenedMap = flattenSettings(
        settingValue,
        newFlattenedMap,
        newFlattenedMap.length,
        newPath
      );
    }
  });

  return newFlattenedMap;
}
