import { IosShare, Save } from "@mui/icons-material";
import TabList from "@mui/lab/TabList";
import {
  Box,
  Button,
  ButtonGroup,
  darken,
  Divider,
  IconButton,
  styled,
  TextField,
  Tooltip,
  useTheme
} from "@mui/material";
import Tab from "@mui/material/Tab";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";
import { serverTimeFormat } from "../../model/database-object.model";
import {
  NestedSettings,
  ValueType
} from "../../model/versioned-settings.model";
import {
  addSetting,
  selectAllSettings,
  selectEditMode,
  selectFutureLength,
  selectPastLength,
  setSettings
} from "./appSettingsSlice";
import { createNestedSettings } from "./utils/createNestedSettings";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const StyledFilter = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 999,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor:
    theme.palette.mode === "light"
      ? "#fff"
      : darken(theme.palette.background.paper, 0.3)
}));

const StyledTabs = styled((props: StyledTabsProps) => (
  <TabList
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    justifyContent: "center"
  },

  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7"
  }
});

interface StyledTabProps {
  label: string;
  value: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  transition: "color .1s ease-in",

  color: "rgba(255, 255, 255, 0.7)",

  "&:hover": {
    color: "#fff"
  },

  "&.Mui-selected": {
    color: "#fff"
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)"
  },
  "& .MuiTabPanel-root": {
    padding: 0
  }
}));

type FilterProps = {
  tabValue: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
};

const Filter = ({ tabValue, handleTabChange }: FilterProps) => {
  const theme = useTheme();

  const editMode = useSelector(selectEditMode);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const currentSettings = useSelector(selectAllSettings);

  const pastLength = useSelector(selectPastLength);
  const futureLength = useSelector(selectFutureLength);

  const [searchTerm, setSearchTerm] = useState("");

  const jsonSettings = createNestedSettings(currentSettings);

  const exportSettings = (settings: NestedSettings) => {
    const settingsToExport: NestedSettings = {
      customGameSettings: {
        __description__: "Game Settings defined by external developer",
        __tag__: "custom",
        __value__: settings,
        __type__: ValueType.OBJECT,
        __user__: "external_user",
        __modified__: moment().format(serverTimeFormat)
      }
    };

    // TODO Add validation and sanitation before export

    copyToClipboard(JSON.stringify(settingsToExport));
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar("Copied to clipboard");
    });
  };

  return (
    <StyledFilter>
      <Box
        width="100%"
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <ButtonGroup size="small">
          <Tooltip title="Undo">
            <span>
              <IconButton
                disabled={pastLength === 0}
                color="primary"
                onClick={() => dispatch(ActionCreators.undo())}
              >
                <UndoIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Redo">
            <span>
              <IconButton
                disabled={futureLength === 0}
                color="primary"
                onClick={() => dispatch(ActionCreators.redo())}
              >
                <RedoIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>
        <Divider
          sx={{
            marginX: theme.spacing(1),
            alignSelf: "stretch",
            height: "auto"
          }}
          orientation="vertical"
        />
        <TextField
          size="small"
          style={{ width: "100%" }}
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          disabled
        />
        <Button
          onClick={() => dispatch(addSetting({ treeData: currentSettings }))}
          sx={{ marginLeft: theme.spacing(1) }}
          variant="contained"
          disabled={!editMode}
        >
          Add
        </Button>
        <Button
          onClick={() => dispatch(setSettings([]))}
          sx={{ marginLeft: theme.spacing(1) }}
          variant="outlined"
          disabled={!editMode}
        >
          Clear
        </Button>
        <Divider
          sx={{
            marginLeft: theme.spacing(1),
            alignSelf: "stretch",
            height: "auto"
          }}
          orientation="vertical"
        />
        <Tooltip title="Copy JSON to clipboard">
          <IconButton
            sx={{ marginLeft: theme.spacing(1) }}
            onClick={() => exportSettings(jsonSettings)}
          >
            <Save />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Editor" value="1" />
          <StyledTab label="JSON" value="2" />
        </StyledTabs>
      </Box>
    </StyledFilter>
  );
};

export default Filter;
