import { Box, Alert, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  SettingTreeOverride,
  ValueType,
} from "../../model/versioned-settings.model";
import { reorder } from "../../utils/reorder";
import { selectEditMode, updateSetting } from "./appSettingsSlice";
import SettingTreeRow from "./SettingTreeRow";

export type SettingTreeItem = {
  id: number;
  value?: any | null;
  name: string;
  parentId: number;
  hasChildren: boolean;
  type: ValueType;
  tag: string;
  description: string;
  modified: string;
  user: string;
  path: string[];
};

type SettingTreeProps = {
  treeData: SettingTreeItem[];
  overrideTreeData: SettingTreeOverride[];
  parentId?: number;
  level?: number;
};

export default function SettingTree({
  treeData,
  overrideTreeData,
  parentId = 0,
  level = 0,
}: PropsWithChildren<SettingTreeProps>) {
  const dispatch = useDispatch();
  const editMode = useSelector(selectEditMode);
  const theme = useTheme();

  const parentItem = treeData.find((itemObj) => itemObj.id === parentId);

  const items = treeData
    .filter((item) => item.parentId === parentId)
    .sort((a, b) =>
      parentItem?.type === ValueType.ARRAY
        ? parseInt(a.name) - parseInt(b.name)
        : a.name.localeCompare(b.name)
    );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    swapIndices(result.source.index, result.destination.index);
  };

  const swapIndices = (sourceIndex: number, destinationIndex: number) => {
    // Get all elements with parent id
    const children = treeData
      .filter((item) => item.parentId === parentId)
      .sort((a, b) => parseInt(a.name) - parseInt(b.name));

    const items = reorder(children, sourceIndex, destinationIndex);

    // update each name with its index
    for (let i = 0; i < items.length; i++) {
      const setting = items[i];
      dispatch(
        updateSetting({
          id: setting.id,
          changes: {
            name: i.toString(),
          },
        })
      );
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ width: "100%", maxWidth: "1100px" }}
                >
                  {!!items.length ? (
                    items.map((item, index) => {
                      const parentItem = treeData.find(
                        (itemObj) => itemObj.id === item.parentId
                      );
                      const isParentArray =
                        parentItem?.type === ValueType.ARRAY;

                      return (
                        <Draggable
                          isDragDisabled={!isParentArray || !editMode}
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SettingTreeRow
                                index={index}
                                item={item}
                                level={level}
                                key={item.id}
                                treeData={treeData}
                                overrideTreeData={overrideTreeData}
                              >
                                <SettingTree
                                  parentId={item.id}
                                  treeData={treeData}
                                  overrideTreeData={overrideTreeData}
                                  level={level + 1}
                                />
                              </SettingTreeRow>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                    <Box
                      sx={{ marginLeft: level !== 0 ? theme.spacing(4) : 0 }}
                      padding={1}
                    >
                      <Alert severity="info">No Settings</Alert>
                    </Box>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
    </>
  );
}
